//
//
//
//
//
//
//
//
//
//
//
//
//

// import BackgroundCanvas from './components/BackgroundCanvas.vue';
import ToolBar from './components/ToolBar.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    // BackgroundCanvas,
    ToolBar,
    Footer,
  },
  mounted() {

  },
}
