//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Sidebar",
  props: {
    activeSection: { type: String, required: false, default: '' }
  },
  data() {
    return {
      sections: this.$router.currentRoute.meta.sections
    };
  },
  mounted() {
    window.addEventListener('hashchange', (event) => {
      event.stopPropagation();
      event.preventDefault();
    });
  },
  methods: {
    handleScroll: path => {
      document.getElementById(path).scrollIntoView({
        behavior: 'smooth'
      });
    },
    downloadCV: event => {
      event.preventDefault();
      window.open("/assets/CV.pdf", "/assets/CV.pdf").focus();
    }
  }
};
