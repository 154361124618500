//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ToolBar',
  data() {
    return {
      routes: this.$router.options.routes.filter(route => route.name)
    };
  }
};
