<template>
  <div id="home-page">
    <p />
  </div>
</template>

<script>
  export default {
    name: 'HomePage',
  }
</script>

<style scoped>
</style>
