//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import JavaIcon from 'simple-icons/icons/openjdk.svg';
import JSIcon from 'simple-icons/icons/javascript.svg';
import PHPIcon from 'simple-icons/icons/php.svg';
import CIcon from 'simple-icons/icons/c.svg';
import CPlusPlusIcon from 'simple-icons/icons/cplusplus.svg';
import CSharpIcon from 'simple-icons/icons/csharp.svg';
import MySQLIcon from 'simple-icons/icons/mysql.svg';
import SQLServerIcon from 'simple-icons/icons/microsoftsqlserver.svg';
import ApacheIcon from 'simple-icons/icons/apache.svg';
import iOSIcon from 'simple-icons/icons/ios.svg';

export default {
  name: 'EducationSection',
  components: {
    JavaIcon,
    JSIcon,
    PHPIcon,
    CIcon,
    CPlusPlusIcon,
    CSharpIcon,
    MySQLIcon,
    SQLServerIcon,
    ApacheIcon,
    iOSIcon,
  }
}
